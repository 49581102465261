import { PortableTextComponentProps } from '@portabletext/react';
import SanityImage, { ImageWithMetadata, imageWithMetadataGroq } from '../components/SanityImage';
import useDataAttribute from '../infrastructure/DataAttributeContext';
import groq from '../infrastructure/groq';
import { RichText } from '../richTextComponents';
import { markDefsGroq } from './markDefsGroq';
import * as style from './richText.module.less';

export const textWithAssetGroq = groq`
{
        _id,
        title,
        body[] {
            ...,
            markDefs[] ${markDefsGroq},
        },
        image ${imageWithMetadataGroq}
    }
`;

type TextWithAssetProps = {
    _key: string;
    title: string;
    body: any;
    image: ImageWithMetadata;
};

const ImageWidths = [172, 172 * 2, 172 * 3];

export function TextWithAsset({ value }: PortableTextComponentProps<TextWithAssetProps>) {
    const attr = useDataAttribute();
    return (
        <aside className={style.textWithAssetCard} data-sanity={attr?.([{ _key: value._key }])}>
            <SanityImage image={value.image} sizes="172px" widths={ImageWidths} />
            <div>
                <h2>{value.title}</h2>
                {value.body && <RichText value={value?.body} />}
            </div>
        </aside>
    );
}
