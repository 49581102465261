import { PortableTextComponentProps } from '@portabletext/react';
import groq from '../infrastructure/groq';

export function VimeoVideo({ value }: PortableTextComponentProps<any>) {
    const videoIdMatch = value.vimeoUrl.match(/vimeo\.com\/(\d+)/);
    const videoId = videoIdMatch ? videoIdMatch[1] : undefined;

    if (!videoId) {
        return null;
    }
    const videoUrl = `https://player.vimeo.com/video/${videoId}`;

    return (
        <div className="video-embed">
            <iframe src={videoUrl} title={value.title} allowFullScreen></iframe>
        </div>
    );
}

export const videoEmbedGroq = groq`
{
    _key,
    _type,
    title,
    vimeoUrl
}
`;
