import imageUrlBuilder from '@sanity/image-url';
import { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';
import { createContext, useContext } from 'react';

const ImageUrlBuilderContext = createContext(imageUrlBuilder());

export function useImageUrlBuilderContext() {
    return useContext(ImageUrlBuilderContext);
}

export function ProvideImageUrlBuilderContext({ children, value }: React.PropsWithChildren<{ value: ImageUrlBuilder }>) {
    return <ImageUrlBuilderContext.Provider value={value}>{children}</ImageUrlBuilderContext.Provider>;
}
