import type { SanityDocument } from '@sanity/client';
import Icon from '../../generic/Icon';
import prettyBytes from '../../utils/prettyBytes';
import SanityImage, { ImageWithMetadata, imageWithMetadataGroq } from '../components/SanityImage';
import useDataAttribute from '../infrastructure/DataAttributeContext';
import groq from '../infrastructure/groq';
import * as style from './WineMagazine.module.less';

export const wineMagazineGroq = groq`{
    _id,
    _type,
    releaseNumber,
    'pdf': pdf.asset -> {
        size,
        url,
        extension
    },
    image ${imageWithMetadataGroq}
}`;

export interface WineMagazineProps extends SanityDocument {
    _type: 'wineMagazine';
    releaseNumber?: string;
    pdf?: {
        url?: string;
        extension?: string;
        size?: number;
        originalFilename?: string;
    } | null;
    image?: ImageWithMetadata;
}

const imageWidths = [114, 114 * 2, 290, 290 * 2];

export function WineMagazine({ pdf, image, releaseNumber }: WineMagazineProps) {
    const dataAttribute = useDataAttribute();

    pdf ??= {};

    return (
        <a
            className={style.wineMagazine}
            href={`${pdf.url}?dl=`}
            download
            aria-label={`Last ned vinbladet utgave ${releaseNumber} (${pdf.extension}, ${prettyBytes(pdf.size ?? 0)})`}
        >
            {image && (
                <SanityImage
                    image={image}
                    widths={imageWidths}
                    sizes="(min-width: 568px) 290px, 114px"
                    data-sanity={dataAttribute?.('image')}
                    aspectRatio={0.8}
                    loading="lazy"
                />
            )}
            <div className={style.info}>
                <h2>Utgave {releaseNumber}</h2>
                <span data-sanity={dataAttribute?.('pdf')}>
                    {pdf.extension} {prettyBytes(pdf.size ?? 0)}
                    <Icon className="icon-download" />
                </span>
            </div>
        </a>
    );
}
