import { Breadcrumb } from '../infrastructure/pageTree';

export default function Breadcrumbs({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) {
    return (
        <nav className="breadcrumbs">
            <ul>
                {breadcrumbs.map(({ _id, path, title }) => (
                    <li key={_id}>
                        <a href={path}>{title}</a>
                    </li>
                ))}
            </ul>
        </nav>
    );
}
