import { PortableTextBlock, PortableTextComponentProps } from '@portabletext/react';
import useDataAttribute from '../infrastructure/DataAttributeContext';
import groq from '../infrastructure/groq';
import { SimpleRichText } from '../richTextComponents';
import { markDefsGroq } from './markDefsGroq';

interface FactBox {
    _key: string;
    title: string;
    simpleRichText: PortableTextBlock;
}

export const factBoxGroq = groq`{
    title,
    simpleRichText[]
    {
        ...,
        markDefs[] ${markDefsGroq},
    },
}`;

export function FactBox({ value }: PortableTextComponentProps<FactBox>) {
    const attr = useDataAttribute();
    return (
        <aside className="fact-box" data-sanity={attr?.([{ _key: value._key }])}>
            <h2>{value.title}</h2>
            <SimpleRichText value={value.simpleRichText} />
        </aside>
    );
}
