import { PortableTextMarkComponentProps } from '@portabletext/react';
import groq from '../infrastructure/groq';

export const internalLinkGroq = groq`
{
    _type,
    internalReference,
    anchor
}
`;

export function InternalLink({
    value,
    children,
}: PortableTextMarkComponentProps<{
    _type: 'internalLink';
    internalReference?: {
        _type: string;
        _ref: string;
        path?: string;
    };
    anchor?: string;
}>) {
    return <a href={value?.internalReference?.path + (value?.anchor ? '#' + value.anchor : '')}>{children}</a>;
}
