import type { SanityDocument } from '@sanity/client';
import Breadcrumbs from '../components/Breadcrumbs';
import TeaserCards, { TeaserCard } from '../components/TeaserCards';
import { TaggedSanityDocument } from '../grid/Grid';
import { taggedGridItemGroq } from '../grid/TaggedGridItem';
import groq, { queryable } from '../infrastructure/groq';
import SanityPage from '../infrastructure/SanityPage';

interface CategoryPage extends SanityDocument {
    _type: 'categoryPage';
    title: string;
    ingress: string;
    content: TeaserCard[];
    relatedContent: TaggedSanityDocument[];
}

export const categoryPage = queryable<CategoryPage>(groq`
    *[_type == "categoryPage" && _id == $id][0]{
        _id,
        _type,
        title,
        ingress,
        content[] {
            _key,
            title,
            ingress,
            hideTitle,
            hideImages,
            backgroundColor,
            content[]-> ${taggedGridItemGroq},
        },
        relatedContent[]-> ${taggedGridItemGroq}
    }
`);

export default SanityPage<CategoryPage>(({ page, breadcrumbs, dataAttribute: dataSanity }) => (
    <article className="sanity" lang={page.metadata?.language}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <header>
            <h1>{page.title}</h1>
            <p>{page.ingress}</p>
        </header>
        <div className="category-page">
            {page.content?.map(({ _key, ...props }) => <TeaserCards key={_key} {...props} dataSanity={dataSanity?.(['content', { _key }])} />)}
            {page.relatedContent?.length > 0 && (
                <>
                    <h2 className="lined-heading">Les også</h2>
                    <TeaserCards content={page.relatedContent} hideImages hideTitle showCategory dataSanity={dataSanity?.(['relatedContent'])} />
                </>
            )}
        </div>
    </article>
));
