import { ImageWithMetadata, imageWithMetadataGroq } from '../components/SanityImage';
import useDataAttribute from '../infrastructure/DataAttributeContext';
import groq from '../infrastructure/groq';
import { TaggedSanityDocument } from './Grid';
import * as style from './Grid.module.less';
import GridItemImage from './GridItemImage';

export const taggedGridItemGroq = groq`{
    _id,
    _type,
    slug,
    title,
    ingress,
    'category': parent -> title,
    bannerImage ${imageWithMetadataGroq},
    tags[]-> {
        _id,
        name,
        slug,
        mainTagCategory -> {
            _id,
            name,
            slug
        }
    }
}`;

export interface TaggedGridItemProps extends TaggedSanityDocument<'recipeArticle' | 'drinkRecommendationArticle' | 'blogArticle' | 'vintageArticle'> {
    path?: string;
    category?: string;
    title?: string;
    ingress?: string;
    bannerImage?: ImageWithMetadata;
    showIngress?: boolean;
    showCategory?: boolean;
    hideImage?: boolean;
    desktopImageWidth?: number;
}

export default function TaggedGridItem({
    bannerImage,
    path,
    category,
    title,
    ingress,
    tags,
    showCategory = false,
    showIngress = false,
    hideImage = false,
    desktopImageWidth,
}: TaggedGridItemProps) {
    const dataAttr = useDataAttribute();
    return (
        <a href={path} className={style.item}>
            <GridItemImage image={bannerImage} hideImage={hideImage} desktopWidth={desktopImageWidth} />
            <div className={style.info}>
                {showCategory && <span className={style.category}>{category}</span>}
                <h2>{title}</h2>
                {showIngress && <p>{ingress}</p>}
                <ul className={style.tags} data-sanity={dataAttr?.('tags')}>
                    {tags?.filter(Boolean).map((t) => <li key={t.name}>{t.name}</li>)}
                </ul>
            </div>
        </a>
    );
}
