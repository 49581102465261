import { getFormattedDate } from '../../cms/CMSComponent';
import prettyBytes from '../../utils/prettyBytes';
import useDataAttribute from '../infrastructure/DataAttributeContext';
import * as style from './ArticleInfo.module.less';

export interface ArticleInfoProps {
    wineMagazineEdition?: {
        releaseNumber: string;
        pdf?: {
            size: number;
            url: string;
        };
    };
    originalTitle?: string;
    authors?: {
        name?: string;
    }[];
    chef?: {
        name?: string;
        role?: string;
    };
    lastUpdated?: string;
    firstPublished?: string;
}

export function ArticleInfo({ wineMagazineEdition, authors, chef, lastUpdated, firstPublished, originalTitle }: ArticleInfoProps) {
    const dataAttribute = useDataAttribute();
    return (
        <p className={style.articleInfo}>
            {!!wineMagazineEdition?.pdf?.url && (
                <span className={style.noBreak}>
                    Først publisert i{' '}
                    <a href={`${wineMagazineEdition.pdf.url}?dl=`} download>
                        Vinbladet {wineMagazineEdition.releaseNumber} (pdf, {prettyBytes(wineMagazineEdition.pdf.size)})
                    </a>
                </span>
            )}
            {!!originalTitle && (
                <span>
                    <strong>Originaltittel:</strong> {originalTitle}
                </span>
            )}
            {!!authors?.length && (
                <span data-sanity={dataAttribute?.('authors')}>
                    <strong>Tekst:</strong> {authors?.map(nameAndRole).filter(Boolean).join(', ')}
                </span>
            )}
            {!!chef?.name && (
                <span data-sanity={dataAttribute?.(['recipe', 'chef'])}>
                    <strong>Oppskrift:</strong> {nameAndRole(chef)}
                </span>
            )}
            {!!lastUpdated && (
                <span>
                    <strong>Sist oppdatert:</strong> <time dateTime={lastUpdated}>{getFormattedDate(lastUpdated)}</time>
                </span>
            )}
            {!!firstPublished && (
                <span>
                    <strong>Publisert:</strong> <time dateTime={firstPublished}>{getFormattedDate(firstPublished)}</time>
                </span>
            )}
        </p>
    );
}

function nameAndRole({ name, role }: { name?: string; role?: string }) {
    return role ? `${name} - ${role}` : name;
}
