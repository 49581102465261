import { ChangeEventHandler } from 'react';

export interface Props {
    id: string;
    name?: string;
    label: string;
    isChecked: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
    role?: 'switch';
}

export default function Checkbox({ id, name, label, isChecked, onChange, role }: Props) {
    return (
        <div className="checkbox-wrapper">
            <input type="checkbox" id={id} name={name} checked={isChecked} onChange={onChange} role={role} />
            {label && <label htmlFor={id}>{label}</label>}
        </div>
    );
}
