import type { StudioPathLike } from '@sanity/react-loader';
import { type CreateDataAttribute, type CreateDataAttributeProps } from '@sanity/visual-editing';
import { PropsWithChildren, createContext, useContext } from 'react';

type DataAttributeProps = CreateDataAttributeProps & {
    id: string;
    type: string;
};

export type DataSanity = CreateDataAttribute<DataAttributeProps>;

const DataAttributeContext = createContext<undefined | DataSanity>(undefined);

export default function useDataAttribute() {
    return useContext(DataAttributeContext);
}

export function ProvideDataAttribute({ value, children }: PropsWithChildren<{ value: DataSanity }>) {
    return <DataAttributeContext.Provider value={value}>{children}</DataAttributeContext.Provider>;
}

export function ScopeDataAttribute({ path, children }: PropsWithChildren<{ path: StudioPathLike }>) {
    const dataAttribute = useDataAttribute();

    if (!dataAttribute) return children;

    return <DataAttributeContext.Provider value={dataAttribute.scope(path)}>{children}</DataAttributeContext.Provider>;
}

export function CombineDataAttribute({ children, ...props }: PropsWithChildren<DataAttributeProps>) {
    const dataAttribute = useDataAttribute();

    if (!dataAttribute) return children;

    return <DataAttributeContext.Provider value={dataAttribute.combine(props)}>{children}</DataAttributeContext.Provider>;
}
