import { PortableTextBlock } from '@portabletext/react';
import { SanityPageDocument } from '../../layout/SanityHeaderFooterLayout';
import { AlphabetList } from '../components/AlphabetList';
import Breadcrumbs from '../components/Breadcrumbs';
import { Brochure, BrochureProps, brochuresGroq } from '../grid/Brochure';
import GrapeItem, { grapeItemGroq, GrapeItemProps } from '../grid/GrapeItem';
import Grid from '../grid/Grid';
import NewsArticleItem, { newsArticleGroq, NewsArticleProps } from '../grid/NewsArticleItem';
import TaggedGridItem, { taggedGridItemGroq, TaggedGridItemProps } from '../grid/TaggedGridItem';
import { WineMagazine, wineMagazineGroq, WineMagazineProps } from '../grid/WineMagazine';
import groq, { queryable } from '../infrastructure/groq';
import SanityPage from '../infrastructure/SanityPage';
import { RichText, richTextGroq } from '../richTextComponents';

export interface ListPage extends SanityPageDocument {
    _type: 'listPage';
    listType: string;
    list: TaggedGridItemProps[] | NewsArticleProps[] | WineMagazineProps[] | BrochureProps[] | GrapeItemProps[];
    richText: PortableTextBlock[];
}

export const listPage = queryable<ListPage>(groq`
    *[_type == "listPage" && _id == $id][0]{
        _id,
        _type,
        hidden,
        listType,
        slug,
        title,
        subtitle,
        ingress,
        metadata,
        richText[] ${richTextGroq},
        'list': select(
            listType == 'wineMagazine' => *[_type == ^.listType]${wineMagazineGroq} | order(releaseNumber desc),
            listType == 'newsArticle' => *[_type == ^.listType]${newsArticleGroq} | order(firstPublished desc),
            listType == "brochure" => *[_type == ^.listType]${brochuresGroq} | order(title asc),
            listType == "grape" => *[_type == ^.listType]${grapeItemGroq} | order(name asc),
            listType == "vintageArticle" => *[_type == ^.listType]${taggedGridItemGroq} | order(title asc),
            *[_type == ^.listType]${taggedGridItemGroq} | order(title asc)
        )
    }
`);

export default SanityPage<ListPage>(({ page, url, breadcrumbs }) => (
    <article className="sanity" lang={page.metadata?.language}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <header>
            <h1>{page.title}</h1>
            <p>{page.ingress}</p>
        </header>
        <section>
            <RichText value={page.richText} studioPath="richText" />
        </section>
        {page.subtitle && <h2 className="lined-heading">{page.subtitle}</h2>}
        {page?.listType === 'grape' && <AlphabetList list={page.list as GrapeItemProps[]} />}
        <Grid list={page.list} component={ListItem} selectedTags={url.searchParams.getAll('tag')} />
    </article>
));

function ListItem(props: TaggedGridItemProps | NewsArticleProps | WineMagazineProps | BrochureProps | GrapeItemProps) {
    switch (props._type) {
        case 'newsArticle':
            return <NewsArticleItem {...props} />;
        case 'wineMagazine':
            return <WineMagazine {...props} />;
        case 'brochure':
            return <Brochure {...props} />;
        case 'grape':
            return <GrapeItem {...props} />;
        case 'blogArticle':
        case 'vintageArticle':
            return <TaggedGridItem {...props} showIngress />;
        default:
            return <TaggedGridItem {...props} />;
    }
}

export function listPageIsDynamic(page: ListPage) {
    switch (page.listType) {
        case 'grape':
        case 'wineMagazine':
        case 'brochure':
            return false;
        default:
            return true;
    }
}
