import { PortableTextBlock } from '@portabletext/react';
import { SanityDocument } from '@sanity/client';
import { SanityAsset } from '@sanity/image-url/lib/types/types';
import cn from 'classnames';
import { getFormattedDate } from '../../cms/CMSComponent';
import Icon from '../../generic/Icon';
import prettyBytes from '../../utils/prettyBytes';
import groq from '../infrastructure/groq';
import { SimpleRichText } from '../richTextComponents';
import * as style from './richText.module.less';

export type FileComponentProps = SanityDocument & {
    _type: 'fileComponent';
    title: string;
    showUpdatedAt: boolean;
    file: {
        asset: SanityAsset & {
            size: number;
        };
    };
    text: PortableTextBlock;
};

export const fileComponentGroq = groq`{
    _id,
    _updatedAt,
    title,
    showUpdatedAt,
    file {
        asset->
    },
    text[],
}`;

export default function FileComponent({ value, float }: { value: FileComponentProps; float?: 'left' | 'right' }) {
    return (
        <div className={cn(style.fileComponent, { float }, float)}>
            <a href={value.file?.asset.url} download>
                <h3>{value.title}</h3>
                <div>
                    <SimpleRichText value={value.text} />
                    {value.showUpdatedAt && (
                        <p>
                            Sist oppdatert: <time dateTime={value._updatedAt}>{getFormattedDate(value._updatedAt, 'date_time')}</time>
                        </p>
                    )}
                </div>
                {value.file?.asset && (
                    <span className={style.fileInfo}>
                        {value.file.asset.extension} {prettyBytes(value.file.asset.size)}
                        <Icon className="icon-download" />
                    </span>
                )}
            </a>
        </div>
    );
}
