import { stegaClean } from '@sanity/client/stega';
import cn from 'classnames';
import { TaggedSanityDocument } from '../grid/Grid';
import TaggedGridItem from '../grid/TaggedGridItem';
import { CombineDataAttribute } from '../infrastructure/DataAttributeContext';
import * as styles from './TeaserCards.module.less';

export interface TeaseCardsProps {
    title?: string;
    ingress?: string;
    hideImages?: boolean;
    hideTitle?: boolean;
    showCategory?: boolean;
    backgroundColor?: string;
    content: TaggedSanityDocument[];
    dataSanity?: string;
}

export interface TeaserCard {
    _key: string;
    title?: string;
    ingress?: string;
    hideImages?: boolean;
    hideTitle?: boolean;
    backgroundColor?: string;
    content: TaggedSanityDocument[];
}

export default function TeaserCards({ title, content, ingress, backgroundColor, hideImages, hideTitle, showCategory, dataSanity }: TeaseCardsProps) {
    return (
        <div className={cn(styles.teaser, { [styles.green]: stegaClean(backgroundColor) === 'green' })} data-sanity={dataSanity}>
            {!hideTitle && (
                <>
                    <h2>{title}</h2>
                    <p>{ingress}</p>
                </>
            )}
            {content?.filter(Boolean).map((teaser, _, { length }) => (
                <CombineDataAttribute key={teaser._id} id={teaser._id} type={teaser._type}>
                    <TaggedGridItem
                        {...teaser}
                        _type="recipeArticle"
                        showIngress
                        hideImage={hideImages}
                        showCategory={showCategory}
                        desktopImageWidth={calculateImageWidth(length)}
                    />
                </CombineDataAttribute>
            ))}
        </div>
    );
}

/**
 * This function calculates the width of the images in the section based on the same rules as css
 * This way we can load the nice and large images
 *
 * @param count number of images in block
 * @returns width of image in pixels
 */
function calculateImageWidth(count: number) {
    // If there are 4 or more images we fall back to the normal rule
    if (count > 3) return undefined;

    // There is a 40px gap between each image, so subtract that from
    // the available width
    return (1288 - (count - 1) * 40) / count;
}
