import { SanityDocument } from '@sanity/client';
import Icon from '../../generic/Icon';
import prettyBytes from '../../utils/prettyBytes';
import SanityImage, { imageWithMetadataGroq } from '../components/SanityImage';
import useDataAttribute from '../infrastructure/DataAttributeContext';
import * as styles from './Brochure.module.less';

export type BrochureProps = SanityDocument & {
    title: string;
    _type: 'brochure';
    pdf: {
        url: string;
        size: number;
        extension: string;
    };
};

export const brochuresGroq = /* groq */ ` {
    _id,
    _type,
    title,
    image ${imageWithMetadataGroq},
    "pdf": pdf.asset->{
        extension,
        size,
        url
    }
}`;

const imageWidths = [90, 90 * 2, 242, 242 * 2];

export function Brochure({ title, pdf, image }: BrochureProps) {
    const dataAttribute = useDataAttribute();
    return (
        <a
            className={styles.brochure}
            href={`${pdf?.url}?dl=`}
            aria-label={`Last ned brosjyren: ${title} (${pdf?.extension}, ${prettyBytes(pdf?.size ?? 0)})`}
        >
            <SanityImage
                image={image}
                widths={imageWidths}
                sizes="(min-width: 568px) 242px, 90px"
                data-sanity={dataAttribute?.('image')}
                aspectRatio={1.04}
                loading="lazy"
            />

            <div className={styles.info}>
                <h2>{title}</h2>
                <span data-sanity={dataAttribute?.('pdf')}>
                    {pdf?.extension} {prettyBytes(pdf?.size ?? 0)}
                    <Icon className="icon-download" />
                </span>
            </div>
        </a>
    );
}
