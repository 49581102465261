import { PortableTextComponentProps } from '@portabletext/react';
import { Faq } from '../components/Faq';
import groq from '../infrastructure/groq';
import { markDefsGroq } from './markDefsGroq';

export const faqGroq = groq` {
    _id,
    faqItems[]-> {
        _id,
        question,
        answer[] {
            ...,
            markDefs[] ${markDefsGroq}
        }
    }
}`;

export type FaqProps = {
    _id: string;
    faqItems: FaqItem[];
};

export type FaqItem = {
    _id: string;
    question: string;
    answer: any;
};

export function FaqWrapper({ value }: PortableTextComponentProps<FaqProps>) {
    return <Faq faqItems={value.faqItems} />;
}
