import type { SanityDocument } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { ComponentType, lazy } from 'react';
import type { DataSanity } from './DataAttributeContext';
import { ProvideImageUrlBuilderContext } from './ImageUrlBuilderContext';
import { SanityProps } from './getSanityPageProps';
import { Breadcrumb } from './pageTree';

const SanityLiveMode = lazy(() => import('./SanityLiveMode'));

interface SanityPageComponentProps<T extends SanityDocument> {
    page: T;
    url: URL;
    breadcrumbs: Breadcrumb[];
    dataAttribute?: DataSanity;
}

export type SanityPageComponent<T extends SanityDocument> = ComponentType<SanityPageComponentProps<T>>;

export default function SanityPage<T extends SanityDocument>(Component: SanityPageComponent<T>) {
    return (props: SanityProps<T>) => (
        <ProvideImageUrlBuilderContext value={imageUrlBuilder(props.projectDetails)}>
            {props.draftMode ? (
                <SanityLiveMode {...props} component={Component as any} />
            ) : (
                <Component page={props.initial.data} url={new URL(props.url)} breadcrumbs={props.breadcrumbs} />
            )}
        </ProvideImageUrlBuilderContext>
    );
}
