import { SanityDocument } from '@sanity/client';
import { getFormattedDate } from '../../cms/CMSComponent';
import { ImageWithMetadata, imageWithMetadataGroq } from '../components/SanityImage';
import useDataAttribute from '../infrastructure/DataAttributeContext';
import groq from '../infrastructure/groq';
import * as style from './Grid.module.less';
import GridItemImage from './GridItemImage';

export const newsArticleGroq = groq`{
    _id,
    _type,
    title,
    ingress,
    firstPublished,
    bannerImage ${imageWithMetadataGroq}
}`;

export interface NewsArticleProps extends SanityDocument {
    _type: 'newsArticle';
    title: string;
    firstPublished: string;
    bannerImage: ImageWithMetadata;
    path: string;
}

export default function NewsArticleItem({ bannerImage, path, firstPublished, title }: NewsArticleProps) {
    const dataAttribute = useDataAttribute();

    return (
        <a href={path} className={style.item}>
            <GridItemImage image={bannerImage} />
            <div className={style.info}>
                <time dateTime={firstPublished} data-sanity={dataAttribute?.('firstPublished')}>
                    {getFormattedDate(firstPublished)}
                </time>
                <h2>{title}</h2>
            </div>
        </a>
    );
}
