import { PortableTextComponentProps } from '@portabletext/react';
import { CaptionedSanityImage, type ImageWithMetadata } from '../components/SanityImage';
const imageSizes = [358, 358 * 2, 358 * 3, 760];
export function ImageWithMetadata({ value }: PortableTextComponentProps<ImageWithMetadata & { _key: string }>) {
    return (
        <CaptionedSanityImage
            image={value}
            widths={imageSizes}
            sizes="(min-width: 872px) 760px, calc(100vw - 32px)"
            studioPath={[{ _key: value._key }]}
            loading="lazy"
        />
    );
}
