import { GrapeItemProps } from '../grid/GrapeItem';
import * as styles from './AlphabetList.module.less';

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZÆØÅ'.split('');

export const AlphabetList = ({ list }: { list: GrapeItemProps[] }) => {
    const firstTargetByLetter: Record<string, string> = {};

    list.forEach(({ slug }) => {
        const firstLetter = slug[0].toUpperCase();
        if (alphabet.includes(firstLetter) && !firstTargetByLetter[firstLetter]) {
            firstTargetByLetter[firstLetter] = slug;
        }
    });

    return (
        <div className={styles.alphabet}>
            {alphabet.map((letter) =>
                firstTargetByLetter[letter] ? (
                    <a key={letter} href={`#${letter.toLowerCase()}`}>
                        {letter}
                    </a>
                ) : (
                    <span key={letter}>{letter}</span>
                ),
            )}
        </div>
    );
};
