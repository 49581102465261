import type { SanityDocument } from '@sanity/client';
import SanityPage from '../infrastructure/SanityPage';
import groq, { queryable } from '../infrastructure/groq';

interface HomePage extends SanityDocument {
    _type: 'homePage';
    title?: string;
}

export const homePage = queryable<HomePage>(groq`
    *[_type == "homePage" && _id == $id][0]{
        _id,
        _type,
        hidden,
        title
    }
`);

export default SanityPage<HomePage>(({ page }) => (
    <article className="sanity">
        <h1>{page.title}</h1>
    </article>
));
