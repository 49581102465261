import { createClient, SanityDocument } from '@sanity/client';
import { PageEventContext } from '../../cloudflare/types';
import { StaticLayoutProps } from '../../layout/HeaderFooterLayout';
import SanityHeaderFooterLayout from '../../layout/SanityHeaderFooterLayout';
import NotFoundPage from '../../notFoundPage';
import { setCacheControl, setHeader } from '../../utils/responseHelpers';
import fetchPaths from '../infrastructure/fetchPaths';
import { getConfig } from '../infrastructure/getConfig';
import { getSanityPageProps, SanityProps } from '../infrastructure/getSanityPageProps';
import { GroqString, ParamsOf, queryable, QueryOf } from '../infrastructure/groq';
import BlogArticle, { blogArticle } from './BlogArticle';
import CategoryPage, { categoryPage } from './CategoryPage';
import DrinkRecommendationArticle, { drinkRecommendationArticle } from './DrinkRecommendationArticle';
import HomePage, { homePage } from './HomePage';
import InformationArticle, { informationArticle } from './InformationArticle';
import ListPage, { listPage, listPageIsDynamic } from './ListPage';
import NewsArticle, { newsArticle } from './NewsArticle';
import PocPage, { pocPage } from './PocPage';
import RecipeArticle, { recipeArticle } from './RecipeArticle';
import VintageArticle, { vintageArticle } from './VintageArticle';
import WineCountryArticle, { wineCountryArticle } from './WineCountryArticle';
import WineDistrictArticle, { wineDistrictArticle } from './WineDistrictArticle';

// Redirect info is part of the path info, so nothing is needed in the query
const redirect = queryable<SanityDocument<{ _type: 'redirect' }>>('');

const knownQueries = {
    blogArticle,
    categoryPage,
    drinkRecommendationArticle,
    homePage,
    informationArticle,
    listPage,
    newsArticle,
    pocPage,
    recipeArticle,
    redirect,
    vintageArticle,
    wineCountryArticle,
    wineDistrictArticle,
} as const;

export type KnownTypes = keyof typeof knownQueries;
export const knownTypes = Object.keys(knownQueries) as KnownTypes[];

type KnownQueries = (typeof knownQueries)[KnownTypes];

type KnownProps = KnownQueries extends GroqString<infer R, { id: string }> ? R : never;

export type SanityPageProps = KnownQueries extends GroqString<infer R, { id: string }> ? (R extends KnownProps ? SanityProps<R> : never) : never;

export async function sanityContent(url: URL, ctx: PageEventContext<'/[[slug]]', SanityPageProps>) {
    const clientConfig = getConfig(ctx.env);

    const client = createClient(clientConfig);

    const paths = await fetchPaths(ctx.request);

    const found = paths.byPath[url.pathname];
    // redirect from /oppskrifter/ to /oppskrifter
    if (!found && url.pathname.endsWith('/') && url.pathname !== '/') {
        url.pathname = url.pathname.substring(0, url.pathname.length - 1);
        return Response.redirect(url, 301);
    }

    // Temporarily handle /content/some/slug/here which is moved to /some/slug/here in sanity
    if (!found && url.pathname.startsWith('/content/')) {
        const path = url.pathname.substring('/content'.length);

        // Only redirect to the new path if it's not hidden
        if (paths.byPath[path] && !paths.byPath[path].hidden) {
            return Response.redirect(new URL(path, url));
        }
    }

    // Page was not found in sanity, return undefined and fall back to hybris page.
    if (!found) return undefined;

    // This is a temporary feature, being able to hide for example the home page and category pages until we have finished implementing them.
    if (found.hidden) return undefined;

    // Check if we should redirect somewhere else
    if (found._type === 'redirect' && found.redirectToId) {
        const redirectTo = paths.byId[found.redirectToId];

        // If we don't know where to redirect, then return 404
        if (!redirectTo) return undefined;

        // We only support 302 redirects, 301 are cached too heavily
        return Response.redirect(new URL(redirectTo.path, url), 302);
    }

    const query = knownQueries[found._type];
    const params = { id: found._id };

    // Page was found, but is of unknown type. This really shouldn't happen.
    if (!query) throw new Error(`Expected query, but got undefined for _type ${found._type}`);

    // Get the page from sanity using the query and params.
    const response = await client.fetch<QueryOf<typeof query>, ParamsOf<typeof query>>(query, params, { filterResponse: false });

    // If sanity returns nothing, fall back to hybris page. This shouldn't happen.
    if (!response.result) return undefined;

    // Convert the response into the props needed by the react component
    const props = getSanityPageProps(clientConfig, paths.byId, response, url.toString(), params) as SanityPageProps;

    return ctx //
        .renderToResponse(props)
        .then(setHeader('Cache-Tag', 'sanity'))
        .then(setHeader('cms', 'sanity')) // TODO: remove this when we are done migrating
        .then(setCacheControl(`public, s-maxage=${5 * 60}, stale-while-revalidate=${60 * 60 * 24}`));
}

export default function SanityPage(props: SanityPageProps) {
    switch (props._type) {
        case 'blogArticle':
            return <BlogArticle {...props} />;
        case 'categoryPage':
            return <CategoryPage {...props} />;
        case 'drinkRecommendationArticle':
            return <DrinkRecommendationArticle {...props} />;
        case 'homePage':
            return <HomePage {...props} />;
        case 'informationArticle':
            return <InformationArticle {...props} />;
        case 'listPage':
            return <ListPage {...props} />;
        case 'newsArticle':
            return <NewsArticle {...props} />;
        case 'pocPage':
            return <PocPage {...props} />;
        case 'recipeArticle':
            return <RecipeArticle {...props} />;
        case 'vintageArticle':
            return <VintageArticle {...props} />;
        case 'wineCountryArticle':
            return <WineCountryArticle {...props} />;
        case 'wineDistrictArticle':
            return <WineDistrictArticle {...props} />;
        default:
            console.log('wrong page type', props);
            return <NotFoundPage />;
    }
}

export function SanityStaticLayout({ url, assets, props }: StaticLayoutProps<SanityPageProps>) {
    switch (props._type) {
        case 'listPage':
            return <SanityHeaderFooterLayout props={props} url={url} assets={assets} dynamic={listPageIsDynamic(props.initial.data)} />;
        default:
            return <SanityHeaderFooterLayout props={props} url={url} assets={assets} />;
    }
}
