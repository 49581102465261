import { PortableTextMarkComponentProps } from '@portabletext/react';
import groq from '../infrastructure/groq';

export const externalLinkGroq = groq`
{
    _type,
    href,
    blank
}
`;

export function ExternalLink({
    value,
    children,
}: PortableTextMarkComponentProps<{
    _type: 'link';
    href?: string;
    blank?: boolean;
}>) {
    return (
        <a href={value?.href} target={value?.blank ? '_blank' : undefined} rel={value?.blank ? 'noreferrer' : undefined}>
            {children}
        </a>
    );
}
