import { PortableTextComponentProps } from '@portabletext/react';
import groq from '../infrastructure/groq';
import * as style from './richText.module.less';

export const tableGroq = groq`
{
    _key,
    _type,
    rows[] {
        _key,
        _type,
        cells[]
    }
}
`;

interface Row {
    _key: string;
    _type: 'tableRow';
    cells: (string | Cell)[];
}

interface Table {
    _key: string;
    _type: 'table';
    rows: Row[];
    headerRow?: boolean;
    headerColumn?: boolean;
}

interface Cell {
    _key: string;
    _type: 'tableCell';
    text: string;
    colspan?: number;
    align?: 'left' | 'center' | 'right';
    href?: string;
}

export function Table({ value }: PortableTextComponentProps<Table>) {
    return (
        <div className={style.table}>
            <table>
                <tbody>
                    {value.rows.map((row, r) => (
                        <tr key={row._key}>
                            {row.cells.map((cell, c) => (
                                <Cell key={c} cell={cell} isHeader={isHeader(cell, r, c, value)} />
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

function isHeader(cell: string | Cell, r: number, c: number, { headerColumn, headerRow }: Pick<Table, 'headerColumn' | 'headerRow'>) {
    if (typeof cell === 'string' && r === 0) return true;
    if (headerColumn && c === 0) return true;
    if (headerRow && r === 0) return true;
    return false;
}

interface CellProps {
    cell: Cell | string;
    isHeader: boolean;
}

function Cell({ cell, isHeader }: CellProps) {
    if (isHeader) {
        return typeof cell === 'string' ? (
            <th>{cell}</th>
        ) : (
            <th align={cell.align} colSpan={cell.colspan}>
                {cell.href ? <a href={cell.href}>{cell.text}</a> : cell.text}
            </th>
        );
    } else {
        return typeof cell === 'string' ? (
            <td>{cell}</td>
        ) : (
            <td align={cell.align} colSpan={cell.colspan}>
                {cell.href ? <a href={cell.href}>{cell.text}</a> : cell.text}
            </td>
        );
    }
}
