import SanityImage, { ImageWithMetadata } from '../components/SanityImage';
import useDataAttribute from '../infrastructure/DataAttributeContext';
import fallbackImage from './fallback.svg';

const ImageWidths = [535, 535 * 2, 735, 735 * 2];

interface Props {
    image?: ImageWithMetadata;
    sanityImageName?: string;
    hideImage?: boolean;
    desktopWidth?: number;
}

export default function GridItemImage({ image, sanityImageName = 'bannerImage', hideImage, desktopWidth = 290 }: Props) {
    const dataAttribute = useDataAttribute();

    if (hideImage) return null;

    if (!image) {
        return (
            <picture>
                <img src={fallbackImage} style={{ aspectRatio: 16 / 9 }} />
            </picture>
        );
    }

    return (
        <SanityImage
            image={image}
            sizes={`(min-width: 768px) ${desktopWidth}px, calc(100vw - 32px)`}
            widths={[desktopWidth, desktopWidth * 2, ...ImageWidths]}
            aspectRatio={16 / 9}
            data-sanity={dataAttribute?.(sanityImageName)}
        />
    );
}
