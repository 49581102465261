import { PortableTextComponentProps } from '@portabletext/react';
import groq from '../infrastructure/groq';
import FileComponent, { FileComponentProps, fileComponentGroq } from './fileComponent';
import * as styles from './richText.module.less';
type GridProps = {
    name: string;
    items: FileComponentProps[];
};

export const gridGroq = groq`{
    name,
    items[]-> {
        _type,
        _id,
        _updatedAt,
        _type == "fileComponent" => ${fileComponentGroq}
    },
}`;

function GridItem({ value }: { value: FileComponentProps }) {
    switch (value._type) {
        case 'fileComponent':
            return (
                <li>
                    <FileComponent value={value} />
                </li>
            );
        default:
            return <li style={{ display: 'none' }}>Unknown grid item {value._type}</li>;
    }
}

export function Grid({ value }: PortableTextComponentProps<GridProps>) {
    return (
        <div className={styles.grid}>
            {value.name && <h2 className="lined-heading">{value.name}</h2>}
            <ul>{value?.items?.map((item) => <GridItem key={item._id} value={item} />)}</ul>
        </div>
    );
}
