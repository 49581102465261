import { PortableTextBlock } from '@portabletext/react';
import { SanityPageDocument } from '../../layout/SanityHeaderFooterLayout';
import ClientReactRoot from '../../utils/ClientReactRoot';
import { ArticleInfo } from '../components/ArticleInfo';
import ArticleTags from '../components/ArticleTags';
import Breadcrumbs from '../components/Breadcrumbs';
import { BannerImage, imageWithMetadataGroq } from '../components/SanityImage';
import TableOfContents, { getToc } from '../components/TableOfContents';
import groq, { queryable } from '../infrastructure/groq';
import SanityPage from '../infrastructure/SanityPage';
import { RichText, richTextGroq } from '../richTextComponents';

interface WineDistrictArticle extends SanityPageDocument {
    _type: 'wineDistrictArticle';
    authors: {
        _id: string;
        name: string;
        role: string;
    }[];
    richText: PortableTextBlock[];
}

export const wineDistrictArticle = queryable<WineDistrictArticle>(groq`
    *[_type == "wineDistrictArticle" && _id == $id][0]{
        _id,
        _type,
        _updatedAt,
        hidden,
        authors[] -> {
            _id,
            name,
            role
        },
        tags[] -> {
            ...
        },
        slug,
        title,
        ingress,
        metadata,
        bannerImage ${imageWithMetadataGroq},
        richText[] ${richTextGroq},
    }
`);

export default SanityPage<WineDistrictArticle>(({ page, breadcrumbs }) => (
    <article className="sanity" lang={page.metadata?.language}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <header>
            <h1>{page.title}</h1>
            <p>{page.ingress}</p>

            <ArticleInfo authors={page.authors} />
        </header>
        <BannerImage image={page.bannerImage} aspectRatio={16 / 9} />
        <ArticleTags tags={page.tags} />
        <ClientReactRoot as="nav" className="toc">
            <TableOfContents entries={getToc(page.richText)} contentQuerySelector="article.sanity > section > *" />
        </ClientReactRoot>
        <section>
            <RichText value={page.richText} />
            <ArticleInfo lastUpdated={page?._updatedAt} />
        </section>
    </article>
));
